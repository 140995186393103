import React, { useState } from 'react';
import { Button, Dialog, Grid, Stack, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { detectName, updateCart } from '../../utility/utilityFunctions';
import authService from '../../services/auth.service';
import { setMessage } from '../../redux/alert/AlertSlice';
import useInitialElements from '../../redux/custom-hooks/useInitialElements';
import { getCarteFileStorage2 } from '../../redux/apiCalls';

const DeleteModal = ({ onClose, itemTodelete, setSelectedItem, type }) => {
  console.log('itemTodelete**', itemTodelete);
  const [loading, setLoading] = useState(false);
  const { listeLangues } = useSelector((state) => state.listeLangues);
  const defaultLangue = listeLangues?.find((l) => l.isDefault);
  const currentUser = authService.getCurrentUser();
  const {currentUserInfo}=useSelector(state=>state?.currentUserInfo)
  const {  config } = useSelector((state) => state.carte);
  const { initialfamillemenu, initialPromotion, initialFamilleOption } = useInitialElements();
  const dispatch = useDispatch();
  const {changeLogs}=useSelector(state=>state?.changeLogs)
  console.log(changeLogs)
  const handleDelete = async () => {
    const lastVersionJson=await getCarteFileStorage2(config, currentUser?.id)
    setLoading(true);
    // categories
    const items =
      type === 'categorie'
        ? lastVersionJson?.orderJson?.categories
        : // produits
        type === 'produit'
        ? lastVersionJson?.orderJson?.items
        : type === 'option'
        ? lastVersionJson?.orderJson?.options
        : type === 'familleOptions'
        ? lastVersionJson?.orderJson?.groups_sub_options
        : type === 'familleMenu'
        ? lastVersionJson?.orderJson?.cartes
        : type === 'promotion' && lastVersionJson?.orderJson?.promotions;
    //************************** */
    // categories

    const modifiedItems =
      type === 'categorie'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true, removed_by_admin: true };
            }
            return item;
          })
        : type === 'produit'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true, removed_by_admin: true };
            }
            return item;
          })
        : type === 'option'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true, removed_by_admin: true };
            }
            return item;
          })
        : type === 'familleOptions'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true };
            }
            return item;
          })
        : type === 'familleMenu'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true, removed_by_admin: true  };
            }
            return item;
          })
        : type === 'promotion'
        ? items.map((item) => {
            if (item.idGlobal === itemTodelete.idGlobal) {
              return { ...item, removed: true, removed_by_admin: true };
            }
            return item;
          })
        : (type === 'ingredient' || type === 'singleOption') &&
          lastVersionJson.orderJson.options.map((option) => {
            if (option.items) {
              const updatedItems = option.items.map((item) => {
                if (item.idGlobal === itemTodelete.idGlobal) {
                  return { ...item, removed: true, removed_by_admin: true };
                }
                return item;
              });
              return { ...option, items: updatedItems };
            }
            return option;
          });

    console.log('modifiedItems', modifiedItems);
    const updatedCarteData =
      type === 'categorie'
        ? {
            orderJson: {
              ...lastVersionJson.orderJson,
              categories: modifiedItems,
            },
          }
        : type === 'produit'
        ? {
            orderJson: {
              ...lastVersionJson.orderJson,
              items: modifiedItems,
            },
          }
        : type === 'promotion'
        ? {
            orderJson: {
              ...lastVersionJson.orderJson,
              promotions: modifiedItems,
            },
          }
        : type === 'familleOptions'
        ? {
            orderJson: {
              ...lastVersionJson.orderJson,
              groups_sub_options: modifiedItems,
            },
          }
        : type === 'familleMenu'
        ? {
            orderJson: {
              ...lastVersionJson.orderJson,
              cartes: modifiedItems,
            },
          }
        : (type === 'ingredient' || type === 'option' || type === 'singleOption') && {
            orderJson: {
              ...lastVersionJson.orderJson,
              options: modifiedItems,
            },
          };
         
    try {
      const userName=currentUser?.user_name || currentUser?.roles==="admin" ? "La franchise" : currentUser?.roles==="moderator" ? "Le Resto" : `${currentUserInfo?.prenom} ${currentUserInfo?.nom}`
      await updateCart(config, dispatch, updatedCarteData,  {...currentUser,
        user_name:userName
      }, false, {
        elementId: itemTodelete.idGlobal,
        elementName: detectName(itemTodelete, defaultLangue),
        elementType: type,
        actionType: 'DELETE',
      });
      if (type === 'promotion') {
        setSelectedItem(
          items
            .filter((el) => !el.removed && !el.removed_by_admin)
            .filter((el) => itemTodelete?.idGlobal !== el?.idGlobal)[0]
            ? items
                .filter((el) => !el.removed && !el.removed_by_admin)
                .filter((el) => itemTodelete?.idGlobal !== el?.idGlobal)[0]
            : { ...initialPromotion, id: items.length + 1 },
        );
      } else if (type === 'familleOptions') {
        setSelectedItem(
          items
            .filter((el) => !el.removed)
            .filter((el) => itemTodelete?.idGlobal !== el?.idGlobal)[0]
            ? items
                .filter((el) => !el.removed)
                .filter((el) => itemTodelete?.idGlobal !== el?.idGlobal)[0]
            : { ...initialFamilleOption, id: items.length + 1 },
        );
      } else if (type === 'familleMenu') {
        setSelectedItem(
          items
            .filter((el) => !el.removed && !el.removed_by_admin)
            .filter((el) => itemTodelete?.idGlobal !== el?.idGlobal)[0]
            ? items
                .filter((el) => !el.removed && !el.removed_by_admin)
                .filter((el) => itemTodelete?.idGlobal !== el?.idGlobal)[0]
            : { ...initialfamillemenu, id: items.length + 1 },
        );
      } else {
        setSelectedItem(null);
      }
      dispatch(
        setMessage({
          content: "la suppression de l'element a été éffectué avec success!",
          type: 'success',
        }),
      );
    } catch (error) {
      console.log('error during delete', error);
    }
    setLoading(false);
    onClose();
  };
  console.log('itemTodelete', itemTodelete, setSelectedItem, type);
  return (
    <Dialog open onClose={onClose} fullWidth>
      <Stack p={3}>
        <Grid spacing={2} container>
          <Grid dispaly="flex" textAlign="right" item xs={12}>
            <Close sx={{ cursor: 'pointer' }} onClick={onClose} color="#ff7b00" />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Êtes-vous sûr de vouloir supprimer
              {type === 'categorie'
                ? ' la catégorie'
                : type === 'produit'
                ? ' le produit'
                : type === 'ingredient'
                ? " l'ingrédient"
                : type === 'option'
                ? " l'option"
                : type === 'promotion'
                ? ' la promotion'
                : type === 'familleOptions'
                ? " la famille d'Options"
                : type === 'familleMenu'
                ? ' la famille '
                : type === 'singleOption' && " l'élément d'option"}{' '}
              <strong>
                {type !== 'promotion' && type !== 'familleOptions'
                  ? itemTodelete.name
                  : type === 'promotion'
                  ? itemTodelete.name_promotion
                  : type === 'familleOptions' && itemTodelete.name_group}
              </strong>
              ?
            </Typography>
          </Grid>
          <Grid spacing={2} display="flex" alignItems="center" gap="8px" item xs={12}>
            <Button onClick={onClose} variant="outlined">
              Annuler
            </Button>
            <Button
              disabled={loading}
              sx={{
                backgroundColor: (theme) => theme.palette.error.main,
              }}
              onClick={handleDelete}
              variant="contained"
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
DeleteModal.propTypes = {
  onClose: PropTypes.any.isRequired,
  itemTodelete: PropTypes.any.isRequired,
  setSelectedItem: PropTypes.any.isRequired,
  type: PropTypes.any.isRequired,
};
export default DeleteModal;
