import React, { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
// import Cookies from 'js-cookie';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import getRouter from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ModalProvider } from './context/ModalContext';
import ModalManager from './components/modals/ModalManager';
import { SocketProvider } from './context/SocketContext'; // Importer SocketProvider
import {
  getFranchiseInfo,
  getCarteFileStorage2,
  getItemsOfOptions,
  getListeLangues,
  getsizes,
  getPrinters,
} from './redux/apiCalls';
import authService from './services/auth.service';
import './App.css';
import CustomSnackbar from './components/custum-elements/CustomSnackbar';
import useCartes from './views/campagne/campagnes-hooks/useCartes';
import { markOffline, markOnline, updateUserActivity } from './utility/utilityFunctions';
// import { privateRequest } from './requestMethods';
// import Menuitems from './layouts/full-layout/sidebar/Menuitems';
// import { setMessage } from './redux/alert/AlertSlice';

const App = () => {
  localStorage?.setItem('guideStatus', 'removed');
  localStorage?.removeItem('removeNotification');
  const currentUser = authService.getCurrentUser();
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  // Utilisez useLocation pour obtenir l'objet de localisation
  const location = useLocation();

  // Accédez aux paramètres de la chaîne de recherche de l'URL
  const searchParams = new URLSearchParams(location.search);

  // Récupérez la valeur d'une variable (par exemple, "token")
  const token = searchParams.get('token');
  // const isBlocked = currentUserInfo?.isBlocked;
  const routing = useRoutes(
    getRouter(currentUser, token, localStorage?.getItem('guideStatus'), currentUserInfo?.isBlocked),
  );
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const { config } = useSelector((state) => state.carte);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { sizes3, loading } = useCartes();
  useEffect(() => {
    if (
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesTailles'
      ].includes(pathname)
    ) {
      console.log(currentUser?.id, currentUser?.roles,loading,sizes3)
      if (currentUser?.roles === 'moderator' && currentUserInfo?.fk_franchise) {
        console.log("moderator=>")
        getsizes(currentUserInfo?.fk_franchise?.toString(), dispatch, []);
      } else if (currentUser?.id && currentUser?.roles !== 'moderator' && !loading && sizes3) {
        console.log("franchise=>")
        getsizes(currentUser?.id, dispatch, sizes3);
      }
    }
  }, [currentUser?.id, currentUserInfo?.fk_franchise, sizes3?.length, loading, pathname]);

  useEffect(() => {
    if (
      currentUser?.id &&
      // currentUser?.roles !== 'moderator' &&
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesImprimantes',
      ].includes(pathname)
    ) {
      getPrinters(currentUser?.roles==="moderator" ?  currentUserInfo?.fk_franchise?.toString() :currentUser?.id, dispatch);
    }
  }, [currentUser?.id, pathname, currentUserInfo?.fk_franchise]);

  const FranchiseLangues =
    currentUserInfo?.countryFranchiseInfo?.languages &&
    currentUserInfo?.countryFranchiseInfo?.languages.length !== 0
      ? currentUserInfo?.countryFranchiseInfo?.languages
      : [
          {
            code: 'fr',
            label: 'Français',
            id: 1,
            country_languages: {
              is_default: true,
            },
          },
        ];
  useEffect(() => {
    if (currentUser) {
      getFranchiseInfo(dispatch, currentUser);
    }
  }, [currentUser?.id]);
  useEffect(() => {
    if (
      currentUser?.id &&
      currentUserInfo?.id &&
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesLangues',
      ].includes(pathname)
    ) {
      getItemsOfOptions(currentUser?.id, dispatch);
      if (currentUserInfo && currentUser?.roles !== 'moderator') {
        getListeLangues(
          currentUser?.id,
          dispatch,
          FranchiseLangues,
          currentUserInfo?.countryFranchiseInfo?.name || 'non trouvé',
        );
      }
    }
  }, [currentUser?.id, pathname, currentUserInfo?.id, currentUserInfo?.countryFranchiseInfo?.name]);

  useEffect(() => {
    if (currentUser && config) {
      getCarteFileStorage2(config, currentUser?.roles!=="moderator" ? currentUser?.id : currentUserInfo?.fk_franchise, dispatch);
    }
  }, [config?.campagne, currentUser?.id, currentUserInfo?.fk_franchise]);
  const navigate = useNavigate();
  useEffect(async () => {
    if (
      currentUser?.id &&
      !currentUser?.superAdminLogin &&
      currentUserInfo?.isBlocked &&
      !currentUser?.superAdminLogin
    ) {
      navigate('/accessDenied');
    }
   
  }, [currentUserInfo?.isBlocked, pathname, currentUser?.id, currentUser?.superAdminLogin]);
  updateUserActivity(currentUser, pathname, null)


const userId = `${currentUser?.id}_${currentUser?.roles}${
  currentUser?.roles === 'guest' ? '_' + currentUser?.id_guest : ''
}`;
window.onbeforeunload = () => {
  
  markOffline(userId);
};

let timeout;
let isUserOnline = false;
const resetTimeout = () => {
  if(!isUserOnline && pathname!=="/auth/login"){
    markOnline(userId);
    isUserOnline = true;
  }
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    markOffline(userId);
    isUserOnline = false;
  }, 60000); 
};

window.addEventListener("mousemove", resetTimeout);
window.addEventListener("keypress", resetTimeout);
resetTimeout();
  return (
    <ModalProvider>
      <ModalManager />
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <CustomSnackbar />
          <SocketProvider>
            
            {' '}
            {/* Wrapping the app with SocketProvider */}
            {routing}
          </SocketProvider>
        </RTL>
      </ThemeProvider>
    </ModalProvider>
  );
};

export default App;
