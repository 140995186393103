import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import AuthService from '../../../services/auth.service';
import { privateRequest } from '../../../requestMethods';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        // disabled={page === 0}
        aria-label="première page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        // disabled={page === 0}
        aria-label="page précédente"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="page suivante"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="dernière page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// const commandes = [
//   {
//     id_cmd: 'CMD1680900512671',
//     equipement: 'Equipement 1',
//     total: 433,
//     paid_at: 'vendredi, avril 7, 2023, 23:17',
//   },
//   {
//     id_cmd: 'CMD1680900226794',
//     equipement: 'Equipement 2',
//     total: 342,
//     paid_at: 'samedi, avril 8, 2023, 23:17',
//   },
//   {
//     id_cmd: 'CMD1680900512671',
//     equipement: 'Equipement 3',
//     total: 433,
//     paid_at: 'vendredi, avril 7, 2023, 23:17',
//   },
// ];

const ListCmdTable = ({ datedebut, datefin, idResto, handleClickDetails, selectedId }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [commandes, setCommandes] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - commandes.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // POUR LINEPOGRESS
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  // POUR LINEPOGRESS

  const getListeRestoParams = () => {
    privateRequest
      .get(`/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`)
      .then((response) => {
        setMesRestoParams(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (UserRoles === 'guest') {
      getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscount = () => {
    setCommandes([]);
    if (UserRoles === 'guest') {
      setIsLoading(true);
      // axios.post(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/dashboard/FindAllMesCommandesViewGuest/${currentUser?.id}&${datedebut}&${datefin}`, mesRestoParams)
      privateRequest
        .get(
          `/api/v1/orders/findAllCommandes/${currentUser?.id}&${datedebut}&${datefin}&${idResto}&${page}&${rowsPerPage}`,
        )
        .then((response) => {
          setCommandes(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (idResto > 0) {
      setIsLoading(true);
      privateRequest
        .get(
          `/api/v1/orders/findAllCommandes/${currentUser?.id}&${datedebut}&${datefin}&${idResto}&${page}&${rowsPerPage}`,
        )
        .then((response) => {
          // console.log("laila**> paid", response?.data?.filter(res=>res?.status==="paid"))
          // const sixAM = new Date();
          // sixAM.setHours(6, 0, 0, 0); // Set to 6:00 AM of the current day
          
          // const filteredResults = response?.data?.filter(res => 
          //   res?.status === "paid" && new Date(res?.paid_at) >= sixAM
          // );
          
          // console.log("cmd of <=06:00 ", filteredResults);

          // console.log("laila**> pending", response?.data?.filter(res=>res?.status==="pending"))
          // console.log("laila**> conceled", response?.data?.filter(res=>res?.status===""cancel""))
          setCommandes(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveTypeDiscount();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto, page, rowsPerPage]);

  const ConvertirMille = (value) => {
    const ValueParseFloat = parseFloat(value);
    const FinalValue = ValueParseFloat.toLocaleString('fr-FR');
    return FinalValue;
  };

  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;

  return (
    <>
      <Card variant="outlined">
        <Box sx={{ width: '100%' }}>
          {isLoading && <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />}
        </Box>
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h4">Liste Commandes - Caisse & Borne</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '30px',
              pl: '12px',
            }}
          >
            {[
              {
                label: 'payé',
                status: 'success',
              },
              {
                label: 'En attente',
                status: 'warning',
              },
              {
                label: 'Annulé',
                status: 'error',
              },
            ]?.map((el) => (
              <Box
                sx={{
                  position: 'relative',
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  fontWeight: '500',
                  color: (t) => t.palette.text.textMid2,
                  fontFamily: '"Jost", sans-serif',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '9px',
                    height: '9px',
                    background: (t) => t.palette[el?.status].main,
                    borderRadius: '50%',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '-12px',
                  },
                }}
                className="status"
              >
                {el?.label}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'auto',
              },
            }}
          >
            <Table
              aria-label="Liste des commandes"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">ID Commande</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Total Commande</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Nom Équipement</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Payé le</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {(rowsPerPage > 0
                  ? commandes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : commandes
                ).map((cmd) => ( */}

                {commandes?.map((cmd) => (
                  <TableRow
                    onClick={(event) =>
                      handleClickDetails(event, cmd.siret, cmd.user_keen_id, cmd.id_cmd)
                    }
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={cmd.id_cmd}
                    // selected={isItemSelected}
                    sx={{
                      background:
                        cmd.id_cmd === selectedId
                          ? 'linear-gradient(180deg, #FB5B4E 0%, #F23F5B 100%)'
                          : 'white',
                      cursor: 'pointer',
                      '& .MuiTableCell-root': {
                        color: (t) =>
                          cmd.id_cmd === selectedId ? 'white' : t?.palette?.text?.textMid2,
                        '& p': {
                          color: (t) =>
                            cmd.id_cmd === selectedId ? 'white' : t?.palette?.text?.textMid2,
                        },
                      },
                      '&:hover': {
                        background: 'linear-gradient(180deg, #FB5B4E 0%, #F23F5B 100%)',
                        color: 'white',
                        '& .MuiTableCell-root': {
                          color: 'white',
                          '& p': {
                            color: 'white',
                          },
                        },
                      },
                    }}
                  >
                    <TableCell>
                      <Chip
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette[
                              cmd?.status === 'paid'
                                ? 'success'
                                : cmd?.status === 'pending'
                                ? 'warning'
                                : 'error'
                            ].main,
                          color: (theme) =>
                            theme.palette[
                              cmd?.status === 'paid'
                                ? 'success'
                                : cmd?.status === 'pending'
                                ? 'warning'
                                : 'error'
                            ].light,
                          borderRadius: '6px',
                          pl: '2px',
                          pr: '2px',
                        }}
                        size="small"
                        label={cmd.id_cmd}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography align="right" variant="h6">
                        {ConvertirMille(cmd.total)} {currencySymbol}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <Chip
                        sx={{
                          backgroundColor: (theme) => theme.palette.info.light,
                          color: (theme) => theme.palette.info.dark,
                          borderRadius: '6px',
                          pl: '3px',
                          pr: '3px',
                        }}
                        size="small"
                        label={cmd.equipement}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary">
                        {moment(cmd.paid_at?.split('Z')[0]).format('ddd DD MMM yyyy HH:mm:ss')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    sx={{ textAlign: 'left' }}
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200, { label: 'Tout', value: -1 }]}
                    colSpan={6}
                    count={commandes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputprops: {
                        'aria-label': 'Lignes par page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ListCmdTable;

ListCmdTable.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
  handleClickDetails: PropTypes.func.isRequired,
  selectedId: PropTypes.func.isRequired,
};
