import { Box, Dialog, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import { ArrowDropUp, Info } from '@material-ui/icons';
import { ConvertirMonetaire, addSpacesToNumber } from '../../../utility/utilityFunctions';
import authService from '../../../services/auth.service';
import { privateRequest } from '../../../requestMethods';
import Error from '../../../components/custum-elements/Error';
import MaximizeCircle from '../../../components/icons/MaximizeCircle';
import Loading from '../../../components/custum-elements/Loading';
import useDebounce from '../../../redux/custom-hooks/useDebounce';
import useEtablissements from '../../../redux/custom-hooks/useEtablissements';

const ClassementRestaus = ({ tableExtended, date, forDay }) => {
  const [displayDetails, setdisplayDetails] = useState([]);
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const formattedDate = dateFormat(date, 'yyyy-mm-dd');
  const headList = [
    {
      label: 'Total Ttc',
      key: 'totalTtc',
    },
    // {
    //   label: 'Total plateforme',
    //   key: 'totalPlateforme',
    // },
    

    {
      label: 'Nombres de commandes',
      key: 'nombrescommandes',
    },
    {
      label: 'Panier moyen',
      key: 'panierMoyen',
    },
    {
      label: 'CA + plateforme',
      key: 'totalTtcPlusPlateforme',
    },
    {
      label: 'Uber eats',
      key: 'totalUber',
    },
    {
      label: 'Delivero',
      key: 'totalDelivero',
    },
    // {
    //   label:"Equipements",
    //   key:"equipements"
    // },
  ];

  const [maximizeTable, setmaximizeTable] = useState(false);
  // const [displayDetails, setdisplayDetails]=useState([])
  const [data, setData] = useState([]);
  const {etablissements}=useEtablissements()
  
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const currentUser = authService.getCurrentUser();

  const firstDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth(), 1),
    'yyyy-mm-dd',
  );
  const lastDayOfMonth = dateFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
    'yyyy-mm-dd',
  );
  const getData = async () => {
    setLoading(true);
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CafranchiseIdResto/${currentUser?.id}&${formattedDate}&${formattedDate}`,
        );
        if(currentUser?.roles==="guest"){
          setData(etablissements?.map(guestRestau=>res.data?.find(franchR=>guestRestau?.restos?.pseudo===franchR?.pseudo)));
        }else{
          setData(res.data);
        }
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/CafranchiseIdResto/${currentUser?.id}&${firstDayOfMonth}&${lastDayOfMonth}`,
        );
        if(currentUser?.roles==="guest"){
          setData(etablissements?.map(guestRestau=>res.data?.find(franchR=>guestRestau?.restos?.pseudo===franchR?.pseudo)));
        }else{
          setData(res.data);
        }
      }
      setErrorMessage(null);
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer plus tard.",
      );
      setData([]);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getData();
  // }, [date, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(
    ()=>getData(),
    500,
    [date, currentUser?.siret, currentUser?.id, forDay]
  )
  const onclose = () => {
    setmaximizeTable(false);
  };
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  useEffect(() => {
    setmaximizeTable(false);
  }, [isLaptop]);
  useEffect(() => {
    setdisplayDetails([]);
  }, [maximizeTable]);
  return (
    <Box
      component={maximizeTable ? Dialog : 'div'}
      open={maximizeTable}
      onClose={onclose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
          margin: '20px',
          transform: 'translateY(20px)',
        },
      }}
    >
      <Box
        sx={{
          fontSize: '18px',
          fontWeight: '600',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: (t) => t.palette.text.textDark,
          padding: maximizeTable ? '15px 10px' : '15px 0',
          backgroundColor: maximizeTable ? '#F8F9FD' : 'unset',
          borderBottom: maximizeTable && '1px solid lightgray',
        }}
        className="title"
      >
        Classement restaurants
        <Box
          onClick={() => setmaximizeTable(!maximizeTable)}
          sx={{
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <MaximizeCircle />
        </Box>
      </Box>
      {/* <Box sx={{
          display:"flex",
          alignItems:"center"
        }}>

        <Box sx={{ml:"40px", backgroundColor:"red"}}>Chiffre d'affaire</Box> <Box sx={{ml:"40px"}}>Ventes</Box> <Box sx={{ml:"40px"}}>Panier Moyen</Box>
        </Box> */}
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {(!loading || data.length !== 0) && tableExtended && (
          <>
            <Box
              sx={{
                width: 'calc(100% / 7)',
                background: '#00c6ba75',
                height: '100%',
                position: 'absolute',
                right: '0',
                top: '0',
              }}
            />
            <Box
              sx={{
                width: 'calc(100% / 7)',
                background: '#5cb1097a',
                height: '100%',
                position: 'absolute',
                right: 'calc(100% / 7)',
                top: '0',
              }}
            />
            <Box
              sx={{
                width: 'calc(100% / 7)',
                background: '#00000021',
                height: '100%',
                position: 'absolute',
                right: 'calc(2 * 100% / 7)',
                top: '0',
              }}
            />
          </>
        )}
        <Box
          className="headList"
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            // mb: '10px',
            mt: {md:tableExtended && '20px'},
            padding: {md:tableExtended && '10px 8px'},
            zIndex: 10,
            position: 'relative',
            borderBottom: tableExtended && '1px solid lightgray',
          }}
        >
          <Box sx={{ width: 'calc(100% / 7 )' }} />
          {headList.map(
            (h, index2) =>
              tableExtended && (
                <Box
                  sx={{
                    width: 'calc(100% / 7)',
                    fontSize: '14px',
                    fontWeight: '700',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '3px 0',
                    textTransform: 'capitalize',
                    // backgroundColor:
                    //   h.key === 'totalUber' ? '#5cb109' : h.key === 'totalDelivero' && '#00c6ba',
                    color: h.key === 'totalUber' ? 'white' : h.key === 'totalDelivero' && 'white',
                  }}
                >
                  {h.label}
                  {(index2 === 0 || index2===3) && (
                    <Tooltip
                      title={
                        index2 === 0
                          ? 'Le montant qui reste à payer de TVA.'
                          : 'Le montant dans lequel les TVA sont déjà payées.'
                      }
                    >
                      <Info
                        sx={{
                          fontSize: '20px',
                          color: (t) => t.palette.mainColor,
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
          )}
        </Box>
        <Box
          sx={{
            maxHeight: '100vh',
            overflow: 'hidden',
            overflowY: 'auto',
            width: '100%',
            overflowX: { xs: 'scroll', md: 'hidden' },
          }}
          className="restauList scrollableBox"
        >
          {loading ? (
            <Box
            sx={{
              '& svg': {
                fill: (t) => t.palette.mainColor,
              },
            }}
          >
            <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection:"column",
              justifyContent: 'center',
            }}
          >
            <Loading type="bubbles" width={40} height={40} />
            <Typography sx={{
              fontSize:"13px",
              color: (t) => t.palette.mainColor,
              textAlign:"center",
              px:"10px",
              fontWeight:"500"
            }}>
            En cours de chargement...
            </Typography>
          </Box>
            
          </Box>
          ) : errorMessage ? (
            <Error />
          ) : data?.length === 0 ? (
            <Box>Aucun données à afficher.</Box>
          ) : (
            data
              // .sort((a, b) => b.totalTtcPlusPlateforme - a.totalTtcPlusPlateforme)
              .map((el, index) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    mb: !maximizeTable && '10px',
                    backgroundColor: maximizeTable ? 'white' : index % 2 === 0 && '#f5f5f5',
                    borderRadius: '12px',
                    padding: '10px 8px',
                  }}
                  key={el.pseudo}
                  className="restauItem"
                >
                  <Box
                    onClick={() => {
                      if (maximizeTable) {
                        setdisplayDetails(
                          displayDetails?.includes(index)
                            ? displayDetails.filter((d) => d !== index)
                            : [...displayDetails, index],
                        );
                      }
                    }}
                    sx={{
                      width: {
                        xs: 'calc(2 * 100% / 3)',
                        md: !tableExtended ? 'calc(2 * 100% / 3)' : 'calc(100% / 7 )',
                      },
                      '@media (max-width:425px)': {
                        '&': {
                          flexDirection: 'column',
                          alignItems: 'normal',
                        },
                      },
                      display: 'flex',
                      rowGap: '2px',
                      columnGap: '8px',
                      alignItems: 'center',
                    }}
                    className="bloc"
                  >
                    {(data.some((d) => d.totalTtcPlusPlateforme !== 0)) && (
                      <Box
                        className="classement"
                        component="span"
                        sx={{
                          fontSize: '14px',
                          color: 'white',
                          background:
                            index === 0
                              ? 'linear-gradient(118deg, #FFC047 2.34%, #EB9E09 94.15%)'
                              : index === 1
                              ? 'linear-gradient(118deg, #D8D8D8 2.34%, #757575 94.15%)'
                              : index === 2
                              ? 'linear-gradient(118deg, #FFC047 2.34%, #CD7F32 2.35%, #DD7815 94.15%)'
                              : 'lightgray',
                          width: 'max-content',
                          padding: '2px 5px',
                          borderRadius: '4px',
                        }}
                      >
                        N°{index + 1}
                      </Box>
                    )}
                    <Box
                      component="span"
                      sx={{
                        fontSize: '14px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                        color: (t) => t.palette.text.textDark,
                      }}
                      className="name"
                    >
                      {el?.pseudo?.toLowerCase()}
                    </Box>
                    {!forDay && el.positionDifference !== 0 && (
                      <Tooltip
                        title={
                          el.positionDifference > 0
                            ? `Ce restaurant a amélioré son classement en gagnant ${
                                el.positionDifference
                              } position${
                                el.positionDifference !== 1 ? 's' : ''
                              } par rapport au mois dernier.`
                            : el.positionDifference < 0
                            ? `Ce restaurant a perdu ${Math.abs(el.positionDifference)} position${
                                el.positionDifference !== -1 ? 's' : ''
                              } par rapport au mois dernier.`
                            : 'Ce restauranta maintenu sa position par rapport au mois dernier.'
                        }
                      >
                        <Box
                          component="span"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color:
                              el.positionDifference >= 0
                                ? (t) => t.palette.success.main
                                : (t) => t.palette.error.main,
                          }}
                        >
                          {`${el.positionDifference > 0 ? '+' : ''}${el.positionDifference}`}
                          <ArrowDropUp
                            sx={{
                              transform:
                                el.positionDifference < 0
                                  ? 'rotate(180deg) translateX(calc(1rem / 2 - 3px))'
                                  : 'translateX(calc(-1rem / 2 + 3px))',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: 'calc(100% / 3)',
                        md: !tableExtended ? 'calc(100% / 3)' : 'calc(100% / 7)',
                      },
                      textAlign: { md: tableExtended ? 'center' : 'right', xs: 'right' },
                    }}
                    className="bloc"
                  >
                    {ConvertirMonetaire(currencyCode, currencySymbol, el.totalTtc)}
                  </Box>

                  <Box
                    sx={{
                      overflow: 'hidden',
                      transition: 'max-height .5s ease',
                      maxHeight: {
                        xs: displayDetails.includes(index) ? '100vh' : '0px',
                        md: '100vh',
                      },
                      display: { xs: 'flex', md: tableExtended ? 'flex' : 'none' },
                      flexWrap: 'wrap',
                      gap: { xs: '10px', md: '0' },
                      mt: { xs: '10px', md: '0' },
                      width: { xs: '100%', md: 'calc(100%  /  7 * 5  )' },
                      paddingLeft: { xs: '38px', md: 0 },
                      '@media (max-width:425px)': {
                        '&': {
                          paddingLeft: '0px',
                        },
                      },
                    }}
                    className="bloc"
                  >
                    {headList.map(
                      (element) =>
                        element.key !== 'totalTtc' && (
                          <Box
                            sx={{
                              zIndex: 10,
                              position: 'relative',
                              color: {
                                xs: 'black',
                                md:
                                  (element.key === 'totalUber' ||
                                    element.key === 'totalDelivero') &&
                                  'white',
                              },
                              width: { xs: 'calc(100% / 2 - 5px)', md: 'calc(100% / 5)' },
                              textAlign: { md: 'center' },
                            }}
                          >
                            <Box
                              sx={{
                                display: { xs: 'flex', md: 'none' },
                                color: '#6E7489',
                                fontSize: '14px',
                              }}
                              className="key"
                            >
                              {element.label}
                            </Box>
                            <Box
                              sx={{
                                color: (t) => t.palette.text.textDark,
                                fontSize: '16px',
                                // opacity: element.key==="totalTtcPlusPlateforme" ? 0.6 : 1,
                              }}
                              className="value"
                            >
                              {element.key !== 'nombrescommandes'
                                ? ConvertirMonetaire(currencyCode, currencySymbol, el[element.key])
                                : addSpacesToNumber(el[element.key])}
                            </Box>
                          </Box>
                        ),
                    )}
                  </Box>
                </Box>
              ))
          )}
        </Box>
      </Box>
    </Box>
  );
};

ClassementRestaus.propTypes = {
  tableExtended: PropTypes.any.isRequired,
  date: PropTypes.any.isRequired,
  forDay: PropTypes.any.isRequired,
  // setTableExtended: PropTypes.any,
};
export default ClassementRestaus;
