import React, { useState } from 'react';
import { Button, Dialog, Grid, Stack, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
// import authService from '../../services/auth.service';
import { setMessage } from '../../redux/alert/AlertSlice';
import { firestore, storage } from '../../firebase/firebase';
import authService from '../../services/auth.service';

const DeleteCampagne = ({ onClose, campgangeToDelete,setListCampagnes }) => {
  const db = firestore; // Récupérer une référence à Firestore
  const [loading, setLoading] = useState(false);
  const currentUser = authService.getCurrentUser();
  const dispatch = useDispatch();

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'CartesCampagnes', campgangeToDelete.id));
      console.log('la carte a été supprimer avec succes sur firestore');
      const collectionRef = collection(db, 'RestosCartes');
const queryy = query(
  collectionRef,
  where('cardId', '==', campgangeToDelete.url),
  where('franchiseId', '==', currentUser.id),
);
try {
  const querySnapshot = await getDocs(queryy);

  querySnapshot.forEach(async (docc) => {
    await deleteDoc(docc.ref);
    console.log(`Document with ID ${docc.id} deleted successfully`);
  });
} catch (error) {
  console.error('Error deleting documents: ', error);
}
      const folderRef = storage.ref(`CartesCampagnes/${currentUser?.id}/`);
      const carteRef = folderRef.child(campgangeToDelete.url);
      await carteRef.delete();
      console.log('Carte deleted from storage successfully!');
      setListCampagnes(prev=>prev.filter(c=>c.id!==campgangeToDelete.id))
      dispatch(setMessage({
        content:"vous avez supprimer la carte avec succes!",
        type:"success"
      }))
    } catch (error) {
      console.log('error during deleting campagne', error);
      dispatch(setMessage({
        content:`Une erreur est survenue lors de la supprission de la carte. Veuillez réessayer ultérieurement.`,
        type:"error"
      }))
    }
    setLoading(false);
    onClose()
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <Stack p={3}>
        <Grid spacing={2} container>
          <Grid dispaly="flex" textAlign="right" item xs={12}>
            <Close sx={{ cursor: 'pointer' }} onClick={onClose} color="#ff7b00" />
          </Grid>
          <Grid item xs={12}>
            <Typography>Êtes-vous sûr de vouloir supprimer cette campagne ?</Typography>
          </Grid>
          <Grid spacing={2} display="flex" alignItems="center" gap="8px" item xs={12}>
            <Button onClick={onClose} variant="outlined">
              Annuler
            </Button>
            <Button
              disabled={loading}
              sx={{
                backgroundColor: (theme) => theme.palette.error.main,
              }}
              onClick={handleDelete}
              variant="contained"
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
DeleteCampagne.propTypes = {
  onClose: PropTypes.any.isRequired,
  campgangeToDelete: PropTypes.any.isRequired,
  setListCampagnes: PropTypes.any.isRequired,
};
export default DeleteCampagne;
