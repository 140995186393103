import { Box } from '@material-ui/core';
import React from 'react';
import PropsType from 'prop-types';
import { useSelector } from 'react-redux';
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';
import useAnnulations from '../custom-hooks/useAnnulations';
import useNoteAnnule from '../custom-hooks/useNoteAnnule';
import useLogsPanier from '../custom-hooks/useLogsPanierNew';

const Annulations = ({date, forDay, currentUser}) => {
   
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const { annulations } = useAnnulations(currentUser, date, forDay);
  const { noteAnnule } = useNoteAnnule(currentUser, date, forDay);
  const { logsPanier } = useLogsPanier(currentUser, date, forDay);
  const {
    loading,
    data
  } = annulations
  const {
    loading:loadingNote,
    data:dataNote
  } = noteAnnule
  const {
    loading:loadinglogsPanier,
    data:datalogsPanier
  } = logsPanier
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        '& .promotionTop': {
          display: 'flex',
          alignItems: 'baseline',
          borderRadius: '10px',
          backgroundColor: 'rgba(242, 243, 245, 1)',
          justifyContent: 'space-between',
          px: '20px',
          py: '10px',
          cursor: 'pointer',
        },
      }}
      className="promotionContainer"
    >
      <Box sx={{}} className="promotionTop">
        <Box
          sx={{
            color: (t) => t.palette.textDark,
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          Annulations{' '}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '14px',
            }}
            className="nbreCommandes"
          >
            {loading ? <Box>Loading...</Box> : data?.nombrescommandes} Commandes
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              color: (t) => t.palette.textDark,
              fontSize: '16px',
              fontWeight: 700,
            }}
            className="percent"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, data?.totalTtc)}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          borderRadius: '75px',
          backgroundColor: 'rgba(242, 243, 245, 1)',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        className="promotionTop"
      >
        <Box
          sx={{
            color: (t) => t.palette.textDark,
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          Notes annulées{' '}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '14px',
            }}
            className="nbreCommandes"
          >
            {loadingNote ? <Box>Loading...</Box> : dataNote?.nombrescommandes} Commandes
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              color: (t) => t.palette.textDark,
              fontSize: '16px',
              fontWeight: 700,
            }}
            className="percent"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, dataNote?.totalTtc)}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          borderRadius: '75px',
          backgroundColor: 'rgba(242, 243, 245, 1)',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        className="promotionTop"
      >
        <Box
          sx={{
            color: (t) => t.palette.textDark,
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          Suppression du panier{' '}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '14px',
            }}
            className="nbreCommandes"
          >
            {loadinglogsPanier ? <Box>Loading...</Box> : datalogsPanier?.nombrescommandes} Commandes
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              color: (t) => t.palette.textDark,
              fontSize: '16px',
              fontWeight: 700,
            }}
            className="percent"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, datalogsPanier?.totalTtc)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
Annulations.propTypes = {
    date: PropsType.any.isRequired,
    forDay: PropsType.any.isRequired,
    currentUser: PropsType.any.isRequired,
  };
export default Annulations;
