import React, { useEffect, useState } from 'react';
// import dateFormat from 'dateformat';
import moment from 'moment';
import 'moment/locale/fr';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Fab, Box, Grid } from '@material-ui/core';
import FeatherIcon from 'feather-icons-react';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../../../services/auth.service';
import { privateRequest } from '../../../requestMethods';
import { addSpacesToNumber } from '../../../utility/utilityFunctions';

const TopCards = ({ datedebut, datefin, idResto, clickncollect }) => {
  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;

  const [dataGlobal, setDataGlobal] = useState({});
  const [chiffredAffaire, setChiffredAffaire] = useState(null);
  // const [chiffredAffaireNSub1, setChiffredAffaireNSub1] = useState(null);
  const [pourcentChiffredAffaire, setPourcentChiffredAffaire] = useState(null);
  const [pourcentPM, setPourcentPM] = useState(null);
  const [pourcentNC, setPourcentNC] = useState(null);
  const [paniernoyen, setPaniernoyen] = useState(null);
  // const [paniernoyenNSub, setPaniernoyenNSub] = useState(null);
  const [nombreCommandes, setNombreCommandes] = useState(null);
  // const [nombreCommandesNSub, setNombreCommandesNSub] = useState(null);
  const [mesRestoParams, setMesRestoParams] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getListeRestoParams = () => {
    privateRequest
      .get(
        `/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`,
      )
      .then((response) => {
        setMesRestoParams(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (UserRoles === 'guest') {
      getListeRestoParams();
    }
  }, [UserRoles]);
    const { currentUserInfo } = useSelector((state) => state.currentUserInfo);

  // RECUPERATION BACKGROUND COLOR
  const retrieveDataGlobal = () => {
    const datedebutFormat = moment(datedebut).format('YYYY-MM-DD');
    const datefinFormat = moment(datefin).format('YYYY-MM-DD');

    // console.log(datedebutFormat);
    // console.log(datefinFormat);

    const firstDayOfPreviousMonth = moment(datedebut).add(-1, 'months').format('YYYY-MM-DD');
    const lastDayOfPreviousMonth = moment(datefin).add(-1, 'months').format('YYYY-MM-DD');

    // console.log(firstDayOfPreviousMonth);
    // console.log(lastDayOfPreviousMonth);

    if (UserRoles === 'guest') {
      privateRequest
        .post(
          `/api/v1/dashboard/FindAllMesCommandesTopGuest/${currentUser?.id}&${datedebut}&${datefin}&${firstDayOfPreviousMonth}&${lastDayOfPreviousMonth}`,
          mesRestoParams,
        )
        .then((response) => {
          setDataGlobal(response.data);
          // CHIFFRRE D'AFFAIRE
          setChiffredAffaire(response.data.foundItemN.confirmedorders);

          // CHIFFRRE D'AFFAIRE N-1
          // setChiffredAffaireNSub1(response.data.foundItemNSub1.confirmedorders);

          // PANIER MOYEN
          setPaniernoyen(response.data.foundItemN.cancledorders);

          // PANIER MOYEN N-1
          // const foundItemPanierMoyenNSub = response.data.foundItemNSub1.cancledorders;
          // const montantPanierMoyenNSub = parseFloat(foundItemPanierMoyenNSub);
          // const montantFormatePanierMoyenNSub = montantPanierMoyenNSub.toLocaleString("fr-FR", {style: "currency", currency: "EUR"});
          // setPaniernoyenNSub(montantFormatePanierMoyenNSub);

          setNombreCommandes(response.data.foundItemN.clickncollectorders);
          console.log(nombreCommandes);
          // setNombreCommandesNSub(response.data.foundItemNSub1.clickncollectorders);

          setPourcentChiffredAffaire(response.data.poucentageT2Dates);
          setPourcentPM(response.data.poucentagePM2Dates);
          setPourcentNC(response.data.poucentageNC2Dates);
          console.log(dataGlobal);
        })
        .catch((e) => {
          console.log(e);
        });
    } 
    else if(idResto > 0) {
      console.log("idResto", idResto)
      setIsLoading(true);
      privateRequest
        .get(
          `/api/v1/dashboard/FindAllMesCommandesTop/0&${datedebutFormat}&${datefinFormat}&${firstDayOfPreviousMonth}&${lastDayOfPreviousMonth}&${idResto}`,
        )
        .then((response) => {
          setDataGlobal(response.data);
          // CHIFFRRE D'AFFAIRE
          setChiffredAffaire(response.data.foundItemN.confirmedorders);
          setIsLoading(false);

          // CHIFFRRE D'AFFAIRE N-1
          // setChiffredAffaireNSub1(response.data.foundItemNSub1.confirmedorders);

          // PANIER MOYEN
          setPaniernoyen(response.data.foundItemN.cancledorders);

          // PANIER MOYEN N-1
          // const foundItemPanierMoyenNSub = response.data.foundItemNSub1.cancledorders;
          // const montantPanierMoyenNSub = parseFloat(foundItemPanierMoyenNSub);
          // const montantFormatePanierMoyenNSub = montantPanierMoyenNSub.toLocaleString("fr-FR", {style: "currency", currency: "EUR"});
          // setPaniernoyenNSub(montantFormatePanierMoyenNSub);

          setNombreCommandes(response.data.foundItemN.clickncollectorders);
          // setNombreCommandesNSub(response.data.foundItemNSub1.clickncollectorders);

          setPourcentChiffredAffaire(response.data.poucentageT2Dates);
          setPourcentPM(response.data.poucentagePM2Dates);
          setPourcentNC(response.data.poucentageNC2Dates);
          console.log(dataGlobal);
        })
        .catch((e) => {
          console.log(e);
        });
      }
  };

  useEffect(() => {
    retrieveDataGlobal();
  }, [currentUser?.id,currentUserInfo?.id, datedebut, datefin, mesRestoParams, idResto]);
  console.log("currentUserInfo",currentUserInfo)
  const ConvertirMille = (value) => {
    const ValueParseFloat = parseFloat(value);
    const FinalValue = ValueParseFloat.toLocaleString('fr-FR');
    return FinalValue;
  };

  const sales = [
    {
      id: 1,
      btnbg: 'primary.light',
      btntext: 'primary.main',
      icon: 'trending-up',
      digits: chiffredAffaire,
      subtext: 'NOMBRE DE COMMANDES CONFIRMEES',
      profit: pourcentChiffredAffaire,
      type: pourcentChiffredAffaire > 0 ? 'profit' : 'loss',
    },
    {
      id: 2,
      btnbg: 'error.light',
      btntext: 'error.main',
      icon: 'trending-down',
      digits: paniernoyen ? ConvertirMille(paniernoyen) : 0,
      // digitsNSub1: paniernoyenNSub,
      subtext: 'NOMBRE DE COMMANDES ANNULEES',
      profit: pourcentPM,
      type: pourcentPM > 0 ? 'profit' : 'loss',
    },
    {
      id: 3,
      btnbg: 'warning.light',
      btntext: 'warning.main',
      icon: 'grid',
      // digits: ConvertirMille(nombreCommandes),
      digits: clickncollect?.data?.length > 0 ? ConvertirMille(clickncollect?.data?.length) : 0,
      // digitsNSub1: nombreCommandesNSub,
      subtext: 'NOMBRE DE COMMANDES CLICK&COLLECT',
      profit: pourcentNC,
      type: pourcentNC > 0 ? 'profit' : 'loss',
    },
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        p: 0,
      }}
    >
      <Grid container spacing={0}>
        {sales.map((topcard) => (
          <Grid item xs={6} lg={4} sm={4} key={topcard.id}>
            <CardContent
              sx={{
                borderRight: {
                  xs: '0',
                  sm: '1px solid rgba(0,0,0,0.1)',
                },
                padding: '30px',
                '& :last-child': {
                  borderRight: '0',
                },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  mt: 2,
                }}
              >
                <Fab
                  size="large"
                  aria-label="top-cards"
                  sx={{
                    backgroundColor: topcard.btnbg,
                    color: topcard.btntext,
                    boxShadow: 'none',
                  }}
                >
                  <FeatherIcon icon={topcard.icon} />
                </Fab>
                <Typography color="textSecondary" variant="h6" fontWeight="400" sx={{ ml: 1 }}>
                  {topcard.subtext}
                </Typography>
              </Box>
              {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size="50px" />
                </Box>
              )}
              {!isLoading &&  ( 
              
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  mt: 2,
                }}
              >
                <Typography variant="h3" color="#0b70fb">
                {addSpacesToNumber(topcard.digits)}
                </Typography>
              </Box> )}
            </CardContent>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default TopCards;

TopCards.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
  clickncollect: PropTypes.array.isRequired,
};
