import { Box, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react';
import { ArrowUpward, Info, InfoOutlined, WarningAmber } from '@material-ui/icons';
import PropsType from 'prop-types';
import moment from 'moment';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Stati from '../../../components/icons/Stati';
import { ConvertirMonetaire, addSpacesToNumber } from '../../../utility/utilityFunctions';
import CustumTooltip from '../../../components/custum-elements/CustumTooltip';
import { privateRequest } from '../../../requestMethods';
import usePromotions from '../custom-hooks/usePromotions';
import Commandes from '../../../components/icons/shopping-bag (2) 1.svg';
import PanierMoyen from '../../../components/icons/web 1.svg';
import Ca from '../../../components/icons/token 1.svg';
import Promotions from '../../../components/icons/megaphone-speaker-announcement-advertising-promotion-sound-svgrepo-com 1.svg';
import Annulations from '../../../components/icons/cancel-button-svgrepo-com 1.svg';
import NewClient from '../../../components/icons/dinner-svgrepo-com 1.svg';
import useAnnulations from '../custom-hooks/useAnnulations';
import Loading from '../../../components/custum-elements/Loading';
import useDebounce from '../../../redux/custom-hooks/useDebounce';
import useClients from '../custom-hooks/useClients';

const CaData = ({ forDay, date, currentUser }) => {
  const [data, setData] = useState(null);
  const [loading, setloading] = useState(true);
  const [errorMessage, seterrorMessage] = useState(false);
  const formattedDate = `${dateFormat(date, 'yyyy-mm-dd')}`;
  const currentDate = dateFormat(new Date(), 'yyyy-mm-dd');
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const timezone = currentUserInfo?.countryFranchiseInfo?.timezone;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const customTimeZone = timezone
    ? `${timezone.split('-')[0]}-${timezone.split('-')[1]}`
    : 'Europe-Paris';
  const firstDayOfMonth = dateFormat(
    new Date(date?.getFullYear(), date?.getMonth(), 1),
    'yyyy-mm-dd',
  );
  // compare selected month with current month not current date
  const lastDayOfMonth =
    // currentMonth===formattedMonth ? currentDate :
    dateFormat(new Date(date?.getFullYear(), date?.getMonth() + 1, 0), 'yyyy-mm-dd');
  const getCaData = async () => {
    setloading(true);
    const entryPoint = isMobile ? 'StatsDataPgMobile2' : 'StatsDataPg';
    try {
      if (forDay) {
        const res = await privateRequest.get(
          `/api/v2/dashboard/${entryPoint}/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${formattedDate}&${formattedDate}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }&${customTimeZone}`,
        );
        setData(res.data);
        seterrorMessage(false);
      } else {
        const res = await privateRequest.get(
          `/api/v2/dashboard/${entryPoint}/${
            currentUser?.roles !== 'moderator' ? currentUser?.id : 0
          }&${firstDayOfMonth}&${lastDayOfMonth}&${
            currentUser?.roles === 'moderator' ? currentUser?.id : 0
          }&${customTimeZone}`,
        );
        setData(res.data);
        seterrorMessage(false);
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(true);
    }
    setloading(false);
  };
  // useEffect(() => {
  //   getCaData();
  // }, [date, currentUser?.roles, currentUser?.siret, currentUser?.id, forDay]);
  useDebounce(() => getCaData(), 500, [
    date,
    currentUser?.siret,
    currentUser?.id,
    forDay,
    isMobile,
  ]);
  const { accumulationPercent, promotions } = usePromotions(
    currentUser,
    date,
    forDay,
    currentDate === formattedDate && forDay
      ? data?.moment?.actual?.totalTtc
      : data?.global?.actual?.totalTtc,
  );
  const { annulations } = useAnnulations(currentUser, date, forDay);
  const { NewClients } = useClients(currentUser, date, forDay);
  const initialCaData = [
    {
      type: 'ChiffreDafaire',
      label: 'Chiffre d’affaires',
      montant:
        currentDate === formattedDate && forDay
          ? data?.moment?.actual?.totalTtc
          : data?.global?.actual?.totalTtc,
      percent:
        currentDate === formattedDate && forDay
          ? data?.moment?.percentages?.totalTtc
          : currentDate !== formattedDate && forDay
          ? data?.global?.percentages?.totalTtc
          : currentDate !== formattedDate && !forDay && isMobile
          ? data?.global?.percentages?.totalTtc
          : data?.global?.percentagesYear?.totalTtc,
      montantMoment: data?.moment?.previous?.totalTtc,
      montantGlobal: data?.global?.previous?.totalTtc,
      percentMoment: data?.moment?.percentages?.totalTtc,
      percentGlobal: data?.global?.percentages?.totalTtc,
      montantLastMonth: data?.global?.previous?.totalTtc,
      montantMonthOfLastYear: data?.global?.previousYear?.totalTtc,
      percentLastMonth: data?.global?.percentages?.totalTtc,
      percentMonthOfLastYear: data?.global?.percentagesYear?.totalTtc,
      icon: Ca,
    },
    {
      type: 'Commandes',
      label: 'Commandes',
      montant:
        currentDate === formattedDate && forDay
          ? data?.moment?.actual?.nombrescommandes
          : data?.global?.actual?.nombrescommandes,
      percent:
        currentDate === formattedDate && forDay
          ? data?.moment?.percentages?.nombrescommandes
          : currentDate !== formattedDate && forDay
          ? data?.global?.percentages?.nombrescommandes
          : data?.global?.percentagesYear?.nombrescommandes,
      montantMoment: data?.moment?.previous?.nombrescommandes,
      montantGlobal: data?.global?.previous?.nombrescommandes,
      percentMoment: data?.moment?.percentages?.nombrescommandes,
      percentGlobal: data?.global?.percentages?.nombrescommandes,
      montantLastMonth: data?.global?.previous?.nombrescommandes,
      montantMonthOfLastYear: data?.global?.previousYear?.nombrescommandes,
      percentLastMonth: data?.global?.percentages?.nombrescommandes,
      percentMonthOfLastYear: data?.global?.percentagesYear?.nombrescommandes,
      icon: Commandes,
    },
    {
      type: 'panierMoyen',
      label: 'Panier Moyen',
      montant:
        currentDate === formattedDate && forDay
          ? data?.moment?.actual?.panierMoyen
          : data?.global?.actual?.panierMoyen,
      percent:
        currentDate === formattedDate && forDay
          ? data?.moment?.percentages?.panierMoyen
          : data?.global?.percentagesYear?.panierMoyen,
      montantMoment: data?.moment?.previous?.panierMoyen,
      montantGlobal: data?.global?.previous?.panierMoyen,
      percentMoment: data?.moment?.percentages?.panierMoyen,
      percentGlobal: data?.global?.percentages?.panierMoyen,
      montantLastMonth: data?.global?.previous?.panierMoyen,
      montantMonthOfLastYear: data?.global?.previousYear?.panierMoyen,
      percentLastMonth: data?.global?.percentages?.panierMoyen,
      percentMonthOfLastYear: data?.global?.percentagesYear?.panierMoyen,
      icon: PanierMoyen,
    },
    /////// franchise
    {
      type: 'Promotions',
      label: 'Promotions',
      montant: promotions?.data?.accumulation?.totalDiscount,
      percent: accumulationPercent,
      icon: Promotions,
      onlyFranchise: true,
    },
    {
      type: 'Annulations',
      label: 'Annulations',
      montant: annulations?.data?.totalTtc,
      percent: annulations?.data?.totalTtc
        ? (annulations?.data?.totalTtc * 100) /
          (currentDate === formattedDate && forDay
            ? data?.moment?.actual?.totalTtc
            : data?.global?.actual?.totalTtc)
        : 0,
      icon: Annulations,
      onlyFranchise: true,
    },
    {
      type: 'NouveauClients',
      label: 'Nouveaux Clients',
      montant: NewClients?.data?.actual,
      percent: NewClients?.data?.pourcentage,
      previous: NewClients?.data?.previous,
      icon: NewClient,
      onlyFranchise: true,
    },
  ];
  // const


  return (
    <Box
      component={currentUser?.roles !== 'moderator' && isMobile ? 'div' : 'div'}
      // autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      interval={10000}
      swipeable
      showArrows={false}
      sx={{
        '& .carousel .control-dots .dot': {
          background: (t) => t.palette.mainColor,
        },
        '& .carousel .slide': {
          px: '10px',
        },
      }}
    >
      {/* <Tooltip  title="le montant des plates-formes n'est pas pris en considération dans ce CA.">
          <InfoOutlined sx={{
          color:t=>t.palette.mainColor,
          fontSize:"24px"
        }}/></Tooltip> */}
      {[0].map((i) => (
        <Box
          sx={{
            display: { xs: 'grid', md: 'flex' },
            alignItems: { md: 'center' },
            justifyContent: { md: 'center' },
            gridTemplateColumns: 'calc(50% - 5px) calc(50% - 5px)',
            flexWrap: 'wrap',
            '@media (max-width:375px)': {
              '& .cardItem:first-child': {
                gridColumn: '1/3',
              },
              // '& .cardItem:last-child': {
              //   gridColumn: currentUser?.roles !== 'moderator' && '1/3',
              // },
            },
            '@media (min-width:1060px)': {
              '& .cardItem': {
                width: 'calc(100% / 3 - 10px)',
              },
              // '& .cardItem:last-child': {
              //   gridColumn: currentUser?.roles !== 'moderator' && '1/3',
              // },
              '& .cardItem:last-child .tooltip': {
                left: currentUser?.roles !== 'moderator' && 'unset',
                right: currentUser?.roles !== 'moderator' && '0',
                transform: currentUser?.roles !== 'moderator' && 'none',
              },
            },
            gap: '10px',
            '& .cardItem': {
              position: 'relative',

              background: { md: 'white', xs: 'linear-gradient(180deg, #F9DEF5 0%, #FFF4E1 100%)' },
              px: '13px !important',
              py: { xs: '10px', md: '20px !important' },
              boxShadow: {
                xs: 'none',
                md: '0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
              },
              borderRadius: '6px',

              '& .top': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              '& .top .left': {
                display: 'flex',
                columnGap: { xs: '10px', md: '6px' },
                rowGap: '6px',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: currentUser?.roles === 'moderator' ? 'centre' : 'flex-start',
              },
              height: { md: '160px' },
              width: {
                md:
                  currentUser?.roles === 'moderator'
                    ? 'calc(100% / 2 - 10px)'
                    : 'calc(100% / 3 - 10px)',
                lg:
                  currentUser?.roles === 'moderator'
                    ? 'calc(100% / 3 - 10px)'
                    : 'calc(100% / 6 - 10px)',
              },
            },
          }}
          className="cardsList"
        >
          {' '}
          {initialCaData.map(
            (element, index) =>
              (currentUser?.roles !== 'moderator'
                ? isMobile
                  ? i === 0
                    ? index <= 2
                    : index >= 3
                  : i === 0
                : i === 0 && index <= 2) && (
                <Box
                  key={element.type}
                  sx={{
                    gridRow: (index === 0 || index === 3) && '1/3',
                  }}
                  className="cardItem block"
                >
                  {element.type === 'ChiffreDafaire' && (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        top: '0',
                        right: '0',
                        zIndex: 0,
                      }}
                    >
                      <Tooltip title="le montant des plateformes n'est pas pris en considération dans ce CA.">
                        <Box
                          sx={{
                            position: 'absolute',
                            right: '-20px',
                            top: '-20px',

                            backgroundColor: '#FCE4EC',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <InfoOutlined
                            sx={{
                              color: '#C2185B',
                              fontSize: '24px',
                              transform: 'translate(6px, -6px)',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      flexDirection: 'column',
                      gap: '12px',
                      justifyContent: { xs: 'space-around', md: 'space-between' },
                      zIndex: 1,
                      height: element?.type === 'ChiffreDafaire' ? 'calc(100% - 13px)' : '100%',
                      mt: element?.type === 'ChiffreDafaire' && '13px',
                    }}
                  >
                    <Box className="top">
                      <Box className="left">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '& img': {
                              width: {
                                xs: '26px',
                                sm: '31px',
                              },
                              height: {
                                xs: '26px',
                                sm: '31px',
                              },
                            },
                          }}
                          className="icon"
                        >
                          <img src={element.icon} alt={element.label} />
                        </Box>
                        <Typography
                          sx={{
                            color: (t) => t.palette.text.textDark,
                            fontSize: { xs: '13px', md: '14px' },
                            fontWeight: 600,
                          }}
                        >
                          {element.label}
                        </Typography>
                      </Box>
                      {(
                        element.type === 'Promotions'
                          ? promotions.loading
                          : element.type === 'Annulations'
                          ? annulations.loading
                          : element.type === 'NouveauClients'
                          ? NewClients.loading
                          : loading
                      ) ? (
                        <Box
                          sx={{
                            '& svg': {
                              fill: (t) => t.palette.mainColor,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              right: 0,
                              bottom: 0,
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              backdropFilter: 'blur(1px)',
                              zIndex: 1,
                              background: '#ffffff5e',
                            }}
                          >
                            <Loading type="bubbles" width={40} height={40} />
                            <Typography
                              sx={{
                                fontSize: '13px',
                                color: (t) => t.palette.mainColor,
                                textAlign: 'center',
                                px: '10px',
                                fontWeight: '500',
                              }}
                            >
                              En cours de chargement...
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                          element.type === 'Promotions'
                            ? promotions?.error
                            : element.type === 'Annulations'
                            ? annulations.errorMessage
                            : element.type === 'Annulations'
                            ? annulations.errorMessage
                            : element.type === 'NouveauClients'
                            ? NewClients.errorMessage
                            : errorMessage
                        ) ? (
                        <Box
                          sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            backdropFilter: 'blur(1px)',
                            zIndex: 1,
                            background: '#ffffff5e',
                          }}
                        >
                          <WarningAmber
                            sx={{
                              color: (t) => t.palette.error.main,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: '13px',
                              color: (t) => t.palette.error.main,
                              textAlign: 'center',
                              px: '10px',
                              fontWeight: '500',
                            }}
                          >
                            Une erreur est survenue lors de la récupération des données.
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: { xs: 'none', md: 'flex' },
                            alignItems: 'center',
                            gap: '8px',
                            color:
                              element.type === 'Promotions' || element.type === 'Annulations'
                                ? (t) => t.palette.mainColor
                                : element.percent >= 0
                                ? (t) => t.palette.success.main
                                : (t) => t.palette.error.main,
                          }}
                          className="right"
                        >
                          <Box
                            sx={{
                              fontSize: '15px',
                              fontWeight: '500',
                            }}
                            className="percent"
                          >
                            {element?.percent?.toFixed(2)}%{/* µµ */}
                          </Box>
                          <CustumTooltip
                            Children={
                              element.type !== 'Promotions' && element.type !== 'Annulations' ? (
                                <Box
                                  sx={{
                                    backgroundColor:
                                      element.percent >= 0
                                        ? (t) => t.palette.success.light
                                        : (t) => t.palette.error.light,
                                    borderRadius: '50%',
                                    transform: element?.percent < 0 && 'rotate(180deg)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '21px',
                                    cursor: 'pointer',
                                  }}
                                  className="icon"
                                >
                                  <ArrowUpward />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    backgroundColor: (t) => t.palette.mainColor,
                                    borderRadius: '50%',
                                    transform: element?.percent < 0 && 'rotate(180deg)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '21px',
                                    cursor: 'pointer',
                                  }}
                                  className="icon"
                                >
                                  <Info
                                    sx={{
                                      color: 'white',
                                    }}
                                  />
                                </Box>
                              )
                            }
                            content={
                              element.type !== 'Promotions' &&
                              element.type !== 'Annulations' &&
                              element.type !== 'NouveauClients' ? (
                                <Box
                                  sx={{
                                    fontSize: '12px',
                                    // width: '185px',
                                    display: 'flex',
                                    // justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    gap: '8px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '40px',
                                      justifyContent: 'space-between',
                                    }}
                                    className="moment"
                                  >
                                    {forDay ? (
                                      currentDate === formattedDate && (
                                        <>
                                          {element?.type === 'ChiffreDafaire'
                                            ? 'CA'
                                            : element?.type === 'Commandes'
                                            ? 'Nombre Commandes'
                                            : 'Panier Moyen'}{' '}
                                          du {moment(date).format('dddd').split('.')[0]} dernier à
                                          la même heure{' '}
                                          <Box
                                            sx={{
                                              fontWeight: '600',
                                            }}
                                          >
                                            {element?.type === 'Commandes'
                                              ? addSpacesToNumber(element?.montantMoment)
                                              : ConvertirMonetaire(
                                                  currencyCode,
                                                  currencySymbol,
                                                  element?.montantMoment,
                                                )}{' '}
                                            {`(${element?.percentMoment}%)`}
                                          </Box>
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {element?.type === 'ChiffreDafaire'
                                          ? 'CA'
                                          : element?.type === 'Commandes'
                                          ? 'Nombre Commandes'
                                          : 'Panier Moyen'}{' '}
                                        du mois dernier{' '}
                                        <Box
                                          sx={{
                                            fontWeight: '600',
                                          }}
                                        >
                                          {element?.type === 'Commandes' ||
                                          element?.type === 'NouveauClients'
                                            ? addSpacesToNumber(element?.montantLastMonth)
                                            : ConvertirMonetaire(
                                                currencyCode,
                                                currencySymbol,
                                                element?.montantLastMonth,
                                              )}{' '}
                                          {` (${element?.percentLastMonth}%)`}
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '40px',
                                      justifyContent: 'space-between',
                                    }}
                                    className="global"
                                  >
                                    {forDay ? (
                                      <>
                                        {element?.type === 'ChiffreDafaire'
                                          ? 'CA'
                                          : element?.type === 'Commandes'
                                          ? 'Nombre Commandes'
                                          : 'Panier Moyen'}{' '}
                                        du {moment(date).format('dddd').split('.')[0]} dernier{' '}
                                        {currentDate === formattedDate &&
                                          ' pendant toute la journée'}{' '}
                                        <Box
                                          sx={{
                                            fontWeight: '600',
                                          }}
                                        >
                                          {element?.type === 'Commandes'
                                            ? addSpacesToNumber(element?.montantGlobal)
                                            : ConvertirMonetaire(
                                                currencyCode,
                                                currencySymbol,
                                                element?.montantGlobal,
                                              )}
                                          {` (${element?.percentGlobal}%)`}
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        {element?.type === 'ChiffreDafaire'
                                          ? 'CA'
                                          : element?.type === 'Commandes'
                                          ? 'Nombre Commandes'
                                          : 'Panier Moyen'}{' '}
                                        du mois {moment(date).format('MMMM')} de l'année dernière
                                        <Box
                                          sx={{
                                            fontWeight: '600',
                                          }}
                                        >
                                          {element?.type === 'Commandes'
                                            ? addSpacesToNumber(element?.montantMonthOfLastYear)
                                            : ConvertirMonetaire(
                                                currencyCode,
                                                currencySymbol,
                                                element?.montantMonthOfLastYear,
                                              )}
                                          {` (${element?.percentMonthOfLastYear}%)`}
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              ) : element.type !== 'NouveauClients' ? (
                                <Box
                                  sx={{
                                    fontSize: '12px',
                                  }}
                                >
                                  La part du montant total d'achat qui a été{' '}
                                  {element.type === 'Promotions' ? 'remisée' : 'Annulée'}.
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '40px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    Nombre des nouveaux clients du{' '}
                                    {forDay
                                      ? `${
                                          moment(date).format('dddd').split('.')[0]
                                        } de la semaine dernière`
                                      : `${moment(date).format('MMMM')} de l'année dernière`}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontWeight: '600',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {addSpacesToNumber(element?.previous)}
                                  </Box>
                                </Box>
                              )
                            }
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                      }}
                      className="center"
                    >
                      <Typography
                        sx={{
                          color: (t) => t.palette.text.textDark,
                          fontSize: { md: '14px' },
                          fontWeight: { md: '500' },
                        }}
                      >
                        Total
                      </Typography>
                      {!(element.type === 'Promotions'
                        ? promotions.loading
                        : element.type === 'Annulations'
                        ? annulations.loading
                        : element.type === 'NouveauClients'
                        ? NewClients.loading
                        : loading) && (
                        <Typography
                          sx={{
                            fontSize: { xs: '24px', md: '22px' },
                            fontWeight: { xs: 600, md: 700 },
                            color: 'black',
                          }}
                        >
                          {element?.type === 'Commandes' || element?.type === 'NouveauClients'
                            ? addSpacesToNumber(element.montant)
                            : ConvertirMonetaire(currencyCode, currencySymbol, element.montant)}
                        </Typography>
                      )}
                    </Box>
                    {index === 0 && (
                      <Box
                        sx={{
                          display: { xs: 'flex', md: 'none' },
                          // display: "none",
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        className="bottom"
                      >
                        <Box
                          sx={{
                            fontSize: '20px',
                            letterSpacing: '-1.3px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            '& .tooltipContainer::before': {
                              top: '-16px !important',
                              transform: '',
                            },
                            '& .tooltipContainer:before': {
                              borderTopColor: '#5c5e63 !important',
                              borderBottomColor: 'transparent !important',
                            },
                            '& .tooltipContainer:hover:before': {
                              top: '-6px !important',
                            },
                            '& .tooltipContainer:hover .tooltip': {
                              top: '-46px !important',
                              left: '-75px',
                              transform: 'translateX(0)',
                            },
                            '& .tooltipContainer .tooltip': {
                              top: '-56px !important',
                              left: '-75px',
                              transform: 'translateX(0)',
                            },
                            color:
                              element.percent >= 0
                                ? (t) => t.palette.success.main
                                : (t) => t.palette.error.main,
                          }}
                          className="left"
                        >
                          {(
                            data &&
                            data[forDay && currentDate === formattedDate ? 'moment' : 'global']
                              ?.percentages?.totalTtc
                          )?.toFixed(2)}
                          %
                          <CustumTooltip
                            Children={
                              <InfoOutlined
                                sx={{
                                  color: (t) => t.palette.mainColor,
                                  fontSize: '20px',
                                }}
                              />
                            }
                            content={
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '30px',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Box>
                                  {`${
                                    forDay
                                      ? 'CA du ' +
                                        moment(date).format('dddd').split('.')[0] +
                                        ' dernier' +
                                        (currentDate === formattedDate ? ' à la même heure' : ' ')
                                      : 'Ca du mois dernier'
                                  } `}
                                </Box>
                                <Box
                                  sx={{
                                    fontWeight: '600',
                                  }}
                                >
                                  {`${ConvertirMonetaire(
                                    currencyCode,
                                    currencySymbol,
                                    data &&
                                      data[
                                        forDay && currentDate === formattedDate
                                          ? 'moment'
                                          : 'global'
                                      ]?.previous?.totalTtc,
                                  )}`}
                                </Box>
                              </Box>
                            }
                          />
                        </Box>
                        <Stati />
                      </Box>
                    )}
                  </Box>
                </Box>
              ),
          )}
        </Box>
      ))}
    </Box>
  );
};
CaData.propTypes = {
  forDay: PropsType.any.isRequired,
  date: PropsType.any.isRequired,
  currentUser: PropsType.any.isRequired,
};
export default CaData;
