import { Search } from '@material-ui/icons';
import React, { useEffect, useRef } from 'react'
import PropsType from "prop-types"
import { Box } from '@material-ui/core';


const Searchbar = ({elementToSearch, query, setQuery, anchorEl }) => {
   const inputRef = useRef();
   useEffect(() => {
    if(anchorEl){
      inputRef?.current?.focus();
    }
   }, [anchorEl])
   
  return (
        <Box className='searchbarContainer' sx={{
        backgroundColor:"rgba(242, 243, 245, 1)",
        padding:"10px",
        display:"flex",
        alignItems:"center",
        gap:"8px",
        borderRadius:"25px"
    }}>
        <Search sx={{
            color:t=>t.palette.mainColor,
            fontSize:"25px"
        }} />
        <Box sx={{
            width:"100%",
            "& input":{
                border:"none",
                outline:"none",
                background:"none",
                width:"100%",
                fontSize:"16px",
                fontWeight:500
            }
        }}><input ref={inputRef} value={query} onChange={(e)=>setQuery(e.target.value)} placeholder={`Rechercher ${elementToSearch}...`} type="text" />
</Box>
    </Box>
  )
}
Searchbar.propTypes = {
    elementToSearch: PropsType.any.isRequired,
    query: PropsType.any.isRequired,
    setQuery: PropsType.any.isRequired,
    anchorEl: PropsType.any.isRequired,
  };
export default Searchbar